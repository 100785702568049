import React from "react";
import xs from "../albumArt/xs.jpg";

export default function BabyBlue() {
  return (
    <div className="lyricsDiv">
      <div className="containerLyricsPage">
        <div className="left-div">
          <img src={xs} alt="X's" />
        </div>
        <div className="right-div">
          <h3>Baby Blue Movie</h3>
          <p>
          Baby blue movie with always for tuesdays <br/>
& her love, Sarah, at Brooklyn House Hotel <br/>
</p>
<p>
I’m just a hurricane eye, <br/>
They’re not a little too shy, <br/>
Know how I wanted to sing, <br/>
Know how they wanted to sing <br/>
</p>
<p>
Don’t you understand? <br/>
Don’t you know <br/>
The love that you want’s all the love that you needed? <br/>
Gave me all you had, <br/>
Gave me all the love that you want,<br/>
All the love that you needed <br/>
</p>
<p>
Baby blue movie with always for tuesdays <br/>
& her new bestie, perfectly sweet... <br/>
</p>
<p>
Don’t you understand? <br/>
Don’t you know <br/>
The love that you want’s gonna make it feel sweeter? <br/>
Gave me all you had, <br/>
Gave me all the love that you want, <br/>
All the love that you needed <br/>
          </p>
          
        </div>
      </div>
    </div>
  );
}
