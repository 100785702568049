import React from "react";
import cas from "../albumArt/Cigarettes+After+Sex.jpg";

export default function Younganddumb() {
  return (
    <div className="lyricsDiv">
      <div className="containerLyricsPage">
        <div className="left-div">
          <img src={cas} alt="Young and Dumb" />
        </div>
        <div className="right-div">
          <h3>Young and Dumb</h3>
          <p>
          Wearing black lipstick, bleaching your hair blonde <br />
Put on your socks, cut-offs or jean shorts, vampire fangs &  <br />
 your I Love New York shirt <br />
          </p>
          <p>
          Well I know full well that you are the patron saint of sucking <br /> cock <br />
Señorita, you’re a cheater <br />
Well so am I <br />
          </p>
          <p>
          You wanna go where the girls are young & dumb & hot as <br /> fuck <br />
Where they’re dancing in the street with nothing on <br />
          </p>
          <p>
          We’ll drive your car to the beach with the song on repeat you <br /> showed me <br />
We’ll drive your car to the beach with the song on repeat, oh <br /> baby <br />
          </p>
          <p>
          My heart is racing watching you kissing on my guitar <br />
Faking a tear drop by spraying my eye with that water gun… <br />
          </p>
          <p>
          We wanna go where the girls are young & dumb & hot as <br /> fuck <br />
Where they’re dancing in the street with nothing on… <br />
          </p>
        </div>
      </div>
    </div>
  );
}
