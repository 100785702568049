import React from "react";
import xs from "../albumArt/xs.jpg";

export default function DarkVacay() {
  return (
    <div className="lyricsDiv">
      <div className="containerLyricsPage">
        <div className="left-div">
          <img src={xs} alt="X's" />
        </div>
        <div className="right-div">
          <h3>Dark Vacay</h3>
          <p>
          In summer I’m living on pills & lines <br/>
With someone I love, I don’t really like  <br/>
Been sleeping on the tile & keeping out the light <br/>
Then sipping Château Lafite Rothschild <br/>
</p>
<p>
So come on, come on, dark star <br/>
Been loving you & I can’t get enough, dark star <br/>
We don’t need Brazzers <br/>
</p>
<p>
Feel the world around you, <br/>
Feel the world surround you, <br/>
Feel the world around you, <br/>
Feel it all around you crash <br/>
</p>
<p>
With summertime liquor at five o’clock <br/>
Been drinking too heavy on Adderall<br/>
I listen to the last message that you left <br/>
Then the voice from the suicide hotline… <br/>
</p>
<p>
Feel the world around you, <br/>
Feel the world surround you, <br/>
Feel the world around you, <br/>
Feel it all around you crash & fall… <br/>
          </p>
          
        </div>
      </div>
    </div>
  );
}
