import React from "react";
import tejano_Blue from "../albumArt/Tejano_Blue.jpg";

export default function TejanoBlue() {
  return (
    <div className="lyricsDiv">
      <div className="containerLyricsPage">
        <div className="left-div">
          <img src={tejano_Blue} alt="Sweet" />
        </div>
        <div className="right-div">
          <h3>Tejano Blue</h3>
          <p>
            We wanted to fuck with real love <br />
            Wanted it sweet, so pure & warm <br />
            Never only sleeping over <br />
          </p>
          <p>
            We wanted to fuck like all the time <br />
            & when you got back from your <br />
            flight It was the first thing we did <br />
          </p>
          <p>
            & when you say you want it all <br />I know you want it all <br />
            Baby, take it all from me <br /> & when you drag me on the floor &
            the blue tejano’s on, <br /> You keep your eyes on me
          </p>
          <p>
            So get in the waves, swim in your leather <br /> I always will make
            it feel like you were the last one <br /> Get in the waves like it
            was the first time <br /> Bless your heart, make you part of my life
            forever
          </p>
          <p>
            In shorts so high waist, <br /> The girl in the arcade said they
            were the shortest ever <br /> & the rest like “Holiday”,
            <br /> The live one where she sang, dancing in a polka dot top...
          </p>
          <p>
            So get in the waves, swim in your leather <br /> I always will make
            it feel like you were the last one <br /> Get in the waves like it
            was the first time <br /> Bless your heart, make you part of my life
            forever & always...
          </p>
        </div>
      </div>
    </div>
  );
}
