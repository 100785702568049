import React from "react";
import xs from "../albumArt/xs.jpg";

export default function Xs() {
  return (
    <div className="lyricsDiv">
      <div className="containerLyricsPage">
        <div className="left-div">
          <img src={xs} alt="X's" />
        </div>
        <div className="right-div">
          <h3>X's</h3>
          <p>
          Do it with the lips that you kept when I finally kissed you <br/>
When every single light in the Hills & the Valley went blue <br/>
Make love as soft as cinnamon, <br/>
Know when to take & when to give <br/>
Running out of bed in your leopard print to unlock the door <br/>

          </p>
          <p>
          Sleeping on your fingertips, dreaming with a filthy heart  <br/>
Deep within a threesome-kiss, sweet how the words slip  <br/>
Honest with the love you give, careless with the way you talk  <br/>
Say you want it just like this, sweet how the words slip  <br/>

          </p>
          <p>
          Do it with the lips that you kept when I said I was yours  <br/>
With X’s on the prints, they’re of Marilyn by Bert Stern  <br/>
Lit candles, pink & yellow cake, let’s get fucked up & celebrate  <br/>
Rolling out of bed in your tangled hair to put some clothes on…  <br/>

          </p>
          
        </div>
      </div>
    </div>
  );
}
