import React from "react";
import cry from "../albumArt/Cry-min.jpg";

export default function Hentai() {
  return (
    <div className="lyricsDiv">
      <div className="containerLyricsPage">
        <div className="left-div">
          <img src={cry} alt="Hentai" />
        </div>
        <div className="right-div">
          <h3>HENTAI</h3>
          <p>
            There was a hentai video that I saw <br />
            I told you about the night that we first made love <br />
            About a girl, who as soon as she made you come <br />
            Could show you the future & tell you your fortune <br />
          </p>
          <p>
            Beautiful hearts are in your eyes <br />
            I’ve been waiting for you to fall for me & let me in your life{" "}
            <br />
            I’ve been waiting for you <br />
          </p>
          <p>
            Told you I wanted to die in an airplane crash over the ocean <br />
            That it was romantic, but you didn’t like it <br />
            Thought it was stupid & thinking of me dead was making you feel bad…{" "}
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}
