import React from "react";
import xs from "../albumArt/xs.jpg";

export default function SilverSable() {
  return (
    <div className="lyricsDiv">
      <div className="containerLyricsPage">
        <div className="left-div">
          <img src={xs} alt="X's" />
        </div>
        <div className="right-div">
          <h3>Silver Sable</h3>
          <p>
            Silver Sable style, <br />
            Back in a Marvel Swimsuit Special from the early ‘90s <br />
            Page 11, the second edition, with that look on her face <br />
          </p>
          <p>
            Right now it’s only the light from the closet <br />
            & the radio’s just playing soft hits <br />
            Need that atmosphere, <br />
            I need that sweet life with its decadent ways <br />
          </p>
          <p>
            Stay with me now, I don’t wanna be lonely <br />
            Know how you feel by the way that you hold me <br />
            & when we kiss <br />
            We don’t need to close our eyes in the dark at all <br />
          </p>
          <p>
            Silver Sable style, The way you watch me, sipping from your straw in
            34D <br />
            Whispering the words “vanilla creme pie” <br />
            To see the look on my face <br />
          </p>
          <p>
            & still it’s only the light from the closet <br />
            While “Number of the Beast” is on your Walkman <br />
            & page 11’s gone, <br />
            See that it’s ripped out & hanging up on your wall…
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}
