import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Header from "./components/Header";
import Home from "./components/Home";
import Music from "./components/Music";
import About from "./components/About";
import Shows from "./components/Shows";

import PistolLyr from "./components/Lyrics/PistolLyr";
import YoualliwantLyr from "./components/Lyrics/YoualliwantLyr";
import Bubblegum from "./components/Lyrics/Bubblegum";
import StopWaiting from "./components/Lyrics/StopWaiting";
import Dontletme from "./components/Lyrics/Dontletme";
import Kissit from "./components/Lyrics/Kissit";
import Heavenly from "./components/Lyrics/Heavenly";
import Youaretheonlygoodthing from "./components/Lyrics/Youaretheonlygoodthing";
import Touch from "./components/Lyrics/Touch";
import Hentai from "./components/Lyrics/Hentai";
import Cry from "./components/Lyrics/Cry";
import Fallinginlove from "./components/Lyrics/Fallinginlove";
import Pure from "./components/Lyrics/Pure";
import Neonmoon from "./components/Lyrics/Neonmoon";
import Crush from "./components/Lyrics/Crush";
import Sesamesyrup from "./components/Lyrics/Sesamesyrup";
import K from "./components/Lyrics/K";
import Eachtimeyoufallinlove from "./components/Lyrics/Eachtimeyoufallinlove";
import Sunsetz from "./components/Lyrics/Sunsetz";
import Apocalypse from "./components/Lyrics/Apocalypse";
import Flash from "./components/Lyrics/Flash";
import Sweet from "./components/Lyrics/Sweet";
import OperaHouse from "./components/Lyrics/OperaHouse";
import Truly from "./components/Lyrics/Truly";
import Johnwayne from "./components/Lyrics/Johnwayne";
import Younganddumb from "./components/Lyrics/Younganddumb";
import Affection from "./components/Lyrics/Affection";
import Keeponlovingyou from "./components/Lyrics/Keeponlovingyou";
import Nothingsgonna from "./components/Lyrics/Nothingsgonna";
import Iamfirefighter from "./components/Lyrics/Iamfirefighter";
import Dreamingofyou from "./components/Lyrics/Dreamingofyou";
import Starryeyes from "./components/Lyrics/Starryeyes";
import MotionPictureSoundTrack from "./components/Lyrics/MotionPictureSoundTrack";
import Xs from "./components/Lyrics/Xs";
import TejanoBlue from "./components/Lyrics/TejanoBlue";
import SilverSable from "./components/Lyrics/SilverSable";
import Hideaway from "./components/Lyrics/Hideaway";
import HoldingYou from "./components/Lyrics/HoldingYou";
import DarkVacay from "./components/Lyrics/DarkVacay";
import BabyBlue from "./components/Lyrics/BabyBlue";
import Hot from "./components/Lyrics/Hot";
import BunkerHill from "./components/Lyrics/BunkerHill";
import AmbienSlide from "./components/Lyrics/AmbienSlide";
import { NewHome } from "./components/NewHome";

const routes = [
  { path: "/", element: <NewHome /> },
  { path: "/cas-fan-page", element: <NewHome /> },
  { path: "/music", element: <Music /> },
  { path: "/music/pistol", element: <PistolLyr /> },
  { path: "/music/yourealliwant", element: <YoualliwantLyr /> },
  { path: "/music/bubblegum", element: <Bubblegum /> },
  { path: "/music/stopwaiting", element: <StopWaiting /> },
  {
    path: "/music/MotionPictureSoundTrack",
    element: <MotionPictureSoundTrack />,
  },
  {path:"/music/xs", element:<Xs />},
  { path: "/music/tejano-blue", element: <TejanoBlue /> },
  { path: "/music/silver-sable", element: <SilverSable /> },
  { path: "/music/hideaway", element: <Hideaway /> },
  { path: "/music/holding-you-holding-me", element: <HoldingYou /> },
  { path: "/music/dark-vacay", element: <DarkVacay /> },
  { path: "/music/baby-blue-movie", element: <BabyBlue /> },
  { path: "/music/hot", element: <Hot /> },
  { path: "/music/dreams-from-bunker-hill", element: <BunkerHill /> },
  { path: "/music/ambien-slide", element: <AmbienSlide /> },
  // { path: "/music/comingsoon", element: <ComingSoon /> },
  { path: "/music/dontletme", element: <Dontletme /> },
  { path: "/music/kissit", element: <Kissit /> },
  { path: "/music/heavenly", element: <Heavenly /> },
  { path: "/music/youaretheonly", element: <Youaretheonlygoodthing /> },
  { path: "/music/touch", element: <Touch /> },
  { path: "/music/hentai", element: <Hentai /> },
  { path: "/music/cry", element: <Cry /> },
  { path: "/music/fallinginlove", element: <Fallinginlove /> },
  { path: "/music/pure", element: <Pure /> },
  { path: "/music/neonmoon", element: <Neonmoon /> },
  { path: "/music/crush", element: <Crush /> },
  { path: "/music/sesamesyrup", element: <Sesamesyrup /> },
  { path: "/music/k", element: <K /> },
  { path: "/music/eachtimeyoufallinlove", element: <Eachtimeyoufallinlove /> },
  { path: "/music/sunsetz", element: <Sunsetz /> },
  { path: "/music/apocalypse", element: <Apocalypse /> },
  { path: "/music/flash", element: <Flash /> },
  { path: "/music/sweet", element: <Sweet /> },
  { path: "/music/operahouse", element: <OperaHouse /> },
  { path: "/music/truly", element: <Truly /> },
  { path: "/music/johnwayne", element: <Johnwayne /> },
  { path: "/music/younganddumb", element: <Younganddumb /> },
  { path: "/music/affection", element: <Affection /> },
  { path: "/music/keeponlovingyou", element: <Keeponlovingyou /> },
  { path: "/music/nothingsgonnahurtyoubaby", element: <Nothingsgonna /> },
  { path: "/music/iamfirefighter", element: <Iamfirefighter /> },
  { path: "/music/dreamingofyou", element: <Dreamingofyou /> },
  { path: "/music/starryeyes", element: <Starryeyes /> },
  { path: "/about", element: <Home /> },
  { path: "/shows", element: <Shows /> },
];
function App() {
  const renderedRoutes = routes.map((route, index) => (
    <Route key={index} path={route.path} element={route.element} />
  ));
  return (
    <div>
      <Header />
      <ScrollToTop />
      <div className="hero-container">
        <Routes>{renderedRoutes}</Routes>
      </div>
    </div>
  );
}

export default App;
