import React from "react";
import xs from "../albumArt/xs.jpg";

export default function BunkerHill() {
  return (
    <div className="lyricsDiv">
      <div className="containerLyricsPage">
        <div className="left-div">
          <img src={xs} alt="X's" />
        </div>
        <div className="right-div">
          <h3>Dreams From Bunker Hill</h3>
          <p>
          Baby, it’s been awhile since I’ve just seen you smile <br/>
You know it’s not how it’s supposed to be <br/>
You’re staying up every night smoking those Camel lights <br/>
& drinking on antihistamines <br/>
</p>
<p>
Do you wanna make it forever? <br/>
Do you wanna be my only one? <br/>
Because now I really miss the way it was <br/>
When everything was beautiful with us <br/>
</p>
<p>
I think of us moving into our place at Bunker Hill, <br/>
We felt so happy there <br/>
The time we went for a walk to get wine & candy bars <br/>
& it started to rain… <br/>
</p>
<p>
Do you wanna make it forever? <br/>
Do you want me to stay right here?<br/>
Do you wanna make it forever? <br/>
If you want, I would stay right here… <br/>
          </p>
          
        </div>
      </div>
    </div>
  );
}
