import React from "react";
import xs from "../albumArt/xs.jpg";

export default function HoldingYou() {
  return (
    <div className="lyricsDiv">
      <div className="containerLyricsPage">
        <div className="left-div">
          <img src={xs} alt="X's" />
        </div>
        <div className="right-div">
          <h3>Holding You, Holding Me</h3>
          <p>
          When your love is slow, hits me & I’m fading <br/>
In backyard waterfalls at night when it’s raining <br/>
</p>
<p>

Holding you, holding me <br/>
Kissing forever, we’ll fall together <br/>
Holding you, holding me <br/>
Kissing, you’re all I tasted <br/>
</p>
<p>

When it gets hot, the breeze is hitting slowly <br/>
& I get high on every word you told me… <br/>

Give me just a little more to calm me <br/>

Your love is slow, hits me & I’m fading <br/>
In waterfalls at night when it’s raining… <br/>

          </p>
          
        </div>
      </div>
    </div>
  );
}
