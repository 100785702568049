import React from "react";
import cry from "../albumArt/Cry-min.jpg";

export default function Youaretheonlygoodthing() {
  return (
    <div className="lyricsDiv">
      <div className="containerLyricsPage">
        <div className="left-div">
          <img src={cry} alt="YOU'RE THE ONLY GOOD THING IN MY LIFE" />
        </div>
        <div className="right-div">
          <h3>YOU'RE THE ONLY GOOD THING IN MY LIFE</h3>
          <p>
            You only fuck for love <br />
            Told me you could never get enough <br />
            Posing as a Playboy centerfold <br />
            You could be my Penthouse Pet, I know <br />
          </p>
          <p>
            You make me think of storms on the beaches <br />
            With all the lights off <br />
          </p>
          <p>
            Everything is wrong, but it’s alright <br />
            You’re the only good thing in my life <br />
          </p>
          <p>
            Do what your heart desires <br />
            Love is always strange when it just starts <br />
            Shouldn’t have a care left in the world <br />
            Naked, tanning by the swimming pool… <br />
          </p>

          <p>
            Laying in the sun, <br />
            Never need to tell me when you come <br />
            Because you know that I can just feel it <br />
          </p>

          
        </div>
      </div>
    </div>
  );
}
