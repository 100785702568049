import React from "react";
import xs from "../albumArt/xs.jpg";

export default function Hideaway() {
  return (
    <div className="lyricsDiv">
      <div className="containerLyricsPage">
        <div className="left-div">
          <img src={xs} alt="X's" />
        </div>
        <div className="right-div">
          <h3>Hideaway</h3>
          <p>
          Come on, baby <br/>
We’ll make it alright <br/>
Bring your speaker & a bottle of white <br/>
We’ll go walking in Marina like last time <br/>
To the beaches that nobody else likes <br/>
          </p>
          <p>
          & you’ll put your arms around me <br/>
& take me back into that hideaway <br/>
& you’ll give your love into me <br/>
& take me back into that hideaway <br/>
            </p>
            <p>
            Now the sun’s out, we’re feeling its sweet light <br/>
Waves are crashing, they’re flying those long kites <br/>
& I can see it, I can see where our lives go <br/>
See us sailing across so many oceans… <br/>
                </p>
          
        </div>
      </div>
    </div>
  );
}
