import React from "react";
import xs from "../albumArt/xs.jpg";

export default function AmbienSlide() {
  return (
    <div className="lyricsDiv">
      <div className="containerLyricsPage">
        <div className="left-div">
          <img src={xs} alt="X's" />
        </div>
        <div className="right-div">
          <h3>Ambien Slide</h3>
          <p>
          Said you couldn’t sleep, <br/>
To know I’d been with someone else takes drugs to shut it out <br/>
‍‍& that’s how you’ll get over it <br/>
</p>
<p>
Take my love with zolpidem <br/>
You said you couldn’t help it <br/>
Had everything that you wanted when my love was only yours, <br/>
But now you’re feeling helpless giving in to your Ambien slide <br/>
</p>
<p>
& it seems that you’ll never feel that good again <br/>
If time doesn’t heal, you’ll never feel that good again… <br/>
</p>
<p>
When you slide this way… <br/>
          </p> 
          
        </div>
      </div>
    </div>
  );
}
