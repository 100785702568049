import React from "react";
import Tejano_Blue from "./albumArt/Tejano_Blue.jpg";
import xs from "./albumArt/cig.jpg";

export const NewHome = () => {
  return (
    <>
      <div
        className="container-123"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.991)",
          minHeight: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <img
          src={Tejano_Blue}
          alt="Tejano_Blue"
          style={{
            width: "50%", // Adjust this value as needed
            height: "50%",
            maxWidth: "100%",
          }}
        />
      </div>

    

      {/* <div
        className="container-123"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.991)",
          minHeight: "100vh",
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            backgroundImage: `url(${Tejano_Blue})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            // WebkitBackgroundClip: "text",
            // backgroundClip: "text",
            color: "white",
            fontSize: "10em",
          }}
        >
          Cigarettes
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundImage: `url(${Tejano_Blue})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
            fontSize: "10em", // Adjust as needed
          }}
        >
          After Sex
        </div>
      </div> */}
    </>
  );
};
