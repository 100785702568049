import React from "react";
import xs from "../albumArt/xs.jpg";

export default function Hot() {
  return (
    <div className="lyricsDiv">
      <div className="containerLyricsPage">
        <div className="left-div">
          <img src={xs} alt="X's" />
        </div>
        <div className="right-div">
          <h3>Hot</h3>
          <p>
          & the summer begins with the taste of your lips <br/>
When it’s getting hot inside you <br/>
Think you’d burn through your dress if you kissed me again <br/>
& it’s getting hot inside <br/>
</p>
    <p>
Is it all in my head? <br/>
Because I keep getting scared <br/>
That I’ll always be lost forever, <br/>
But I don’t give a shit if I’m too delicate <br/>
When you hold me it’s always better <br/>
</p>
    <p>
‍‍Now your lips start to taste of pink lemonade <br/>
As I jump off the roof into your pool <br/>
Laugh & run from the heat because it’s burning your feet <br/>
& it’s getting hot inside… <br/>
          </p>
          
        </div>
      </div>
    </div>
  );
}
